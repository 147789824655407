import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import settingsApi from "../../apis/settings-api";

const initialState = {
  data: {},
  loading: false,
  loaded: false,
};

export const getSettings = createAsyncThunk(
  "settings/get",
  async (shopuuid) => {
    const response = await settingsApi.get(shopuuid);
    return response.data;
  }
);

export const updateSettings = createAsyncThunk(
  "settings/update",
  async ({ shopuuid, settings }) => {
    const response = await settingsApi.update(shopuuid, settings);
    return response.data;
  }
);

export const updateStateSettings = createAsyncThunk(
  "settings/updateState",
  async ({ settings }) => {
    return settings;
  }
);

export const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Get Settings
    builder.addCase(getSettings.fulfilled, (state, action) => {
      const payload = action.payload;
      state.data = payload;
      state.loaded = true;
      state.loading = false;
    });
    builder.addCase(getSettings.pending, (state) => {
      state.loading = true;
      state.loaded = false;
    });
    builder.addCase(getSettings.rejected, (state, action) => {
      state.loading = false;
      state.loaded = false;
    });

    // Update Settings
    builder.addCase(updateSettings.fulfilled, (state, action) => {
      const payload = action.payload;
      state.data = payload;
      state.loading = false;
    });
    builder.addCase(updateSettings.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(updateSettings.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(updateStateSettings.fulfilled, (state, action) => {
      const payload = action.payload;
      state.data = {
        ...state.data,
        ...payload,
      };
      state.loading = false;
    });
  },
});

export default settingsSlice.reducer;
