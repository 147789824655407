import { Modal } from "@shopify/polaris";

const ConfirmActionModal = ({
  active,
  onClose,
  title,
  loading,
  content,
  primaryAction,
  primaryActionLabel,
}) => {
  return (
    <Modal
      open={active}
      onClose={onClose}
      title={title}
      primaryAction={{
        loading: loading,
        content: primaryActionLabel ? primaryActionLabel : "Confirm",
        onAction: primaryAction,
      }}
      secondaryActions={[
        {
          content: "Cancel",
          onAction: onClose,
        },
      ]}
    >
      <Modal.Section>{content}</Modal.Section>
    </Modal>
  );
};

export default ConfirmActionModal;
