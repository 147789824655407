import React from "react";

// DONE
const NoData = (props) => {
  const { total = 0 } = props;
  return (
    <div className="IronPop-NoDataTable">
      <div>
        <img
          src={"https://cdn.iron-pop.com/common/no-results.png "}
          alt={"iron-pop-no-result"}
        />
        <div className="heading">No result found</div>
        <p style={{ margin: "4px 0 16px", color: "rgba(109, 113, 117, 1)" }}>
          {total <= 0
            ? "Please create videos for your store now!"
            : "Please try another filters or search terms."}
        </p>
        <div style={{ padding: "40px" }} />
      </div>
    </div>
  );
};

export default NoData;
