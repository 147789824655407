/* eslint-disable react-hooks/exhaustive-deps */
import _ from "lodash";
import React, { useState } from "react";
import {
  ActionList,
  BlockStack,
  Button,
  ChoiceList,
  Icon,
  Popover,
  Select,
  TextField,
} from "@shopify/polaris";
import { FilterIcon, SortIcon, SearchIcon } from "@shopify/polaris-icons";

import {
  VIDEO_SOURCE_TYPES,
  VIDEO_STATUS,
  SORT_OPTIONS,
} from "../../../common/constants/video-library";

const FilterBar = ({ queries = {}, changeQueries, getList }) => {
  const [query, setQuery] = useState("");
  const [activePopover, setActivePopover] = useState(false);
  const [activePopoverSort, setActivePopoverSort] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedSource, setSelectedSource] = useState("");
  let timerTyping = null;

  const handleClear = () => {
    setSelectedSource("");
    setSelectedStatus("");
  };

  const handleApplyFilter = () => {
    // call API filter
    setActivePopover(false);
    const _queries = {
      ...queries,
      q: encodeURIComponent(query) || "",
    };
    if (!_.isEmpty(selectedStatus)) {
      _.set(_queries, "fileStatus", selectedStatus[0]);
    } else delete _queries.fileStatus;
    if (!_.isEmpty(selectedSource)) {
      _.set(_queries, "source", selectedSource);
    } else delete _queries.source;
    changeQueries(_queries);
    getList(_queries);
  };

  const handleApplySort = (value) => {
    setActivePopoverSort(false);
    const _queries = {
      ...queries,
      sortBy: value,
    };
    changeQueries(_queries);
    getList(_queries);
  };

  // Key up event, start searching after 500ms
  const handleKeyUp = (event) => {
    // Invoke search request after 500ms
    timerTyping = setTimeout(() => {
      const _queries = {
        ...queries,
        q: encodeURIComponent(query) || "",
      };
      //   call api to get data by queries
      getList(_queries);
    }, 1000);
  };

  // Key press event on search field
  const handleKeyDown = (event) => {
    clearTimeout(timerTyping);
  };
  const activatorFilters = (
    <Button onClick={() => setActivePopover(true)} icon={FilterIcon} />
  );
  const activatorSort = (
    <Button icon={SortIcon} onClick={() => setActivePopoverSort(true)} />
  );

  return (
    <div className="IronPop-FilterBar">
      <div className="search" onKeyDown={handleKeyDown} onKeyUp={handleKeyUp}>
        <TextField
          value={query}
          onChange={(value) => setQuery(value)}
          placeholder="Search"
          prefix={<Icon source={SearchIcon} tone="base" />}
          autoComplete="off"
        />
      </div>
      <div className="filter">
        <Popover
          active={activePopover}
          activator={activatorFilters}
          onClose={() => setActivePopover(false)}
        >
          <Popover.Pane>
            <Popover.Section>
              <div className="IronPop-FiltersPopover">
                <BlockStack vertical={true} gap={200}>
                  <ChoiceList
                    title={"Status"}
                    choices={VIDEO_STATUS}
                    selected={selectedStatus}
                    onChange={(value) => setSelectedStatus(value)}
                  />
                  <Select
                    options={VIDEO_SOURCE_TYPES.map((o) => ({
                      value: o.name,
                      label: o.label,
                    }))}
                    label={"Source"}
                    placeholder={"Select video source"}
                    onChange={(value) => setSelectedSource(value)}
                    value={selectedSource}
                  />

                  <div className="breakLine">
                    <Button onClick={handleClear} variant="plain">
                      {"Clear all"}
                    </Button>
                    <Button onClick={handleApplyFilter} variant="primary">
                      {"Apply"}
                    </Button>
                  </div>
                </BlockStack>
              </div>
            </Popover.Section>
          </Popover.Pane>
        </Popover>
      </div>
      <div className="sort">
        <Popover
          active={activePopoverSort}
          activator={activatorSort}
          autofocusTarget="first-node"
          onClose={() => setActivePopoverSort(false)}
        >
          <div style={{ width: "112px" }}>
            <ActionList
              actionRole="menuitem"
              items={SORT_OPTIONS.map((o) => ({
                content: o.label,
                onAction: () => handleApplySort(o.value),
              }))}
            />
          </div>
        </Popover>
      </div>
    </div>
  );
};

export default FilterBar;
