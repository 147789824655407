import React, { useEffect, useState } from "react";
import { Modal, Frame, Tabs } from "@shopify/polaris";
import UploadVideo from "./local-upload";
import SourceUpload from "./source-upload";
import { useSelector } from "react-redux";
import { useAppBridge } from "@shopify/app-bridge-react";

const AddNewVideoModal = ({ active, onClose, getFirstPage }) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [step, setStep] = useState(0);
  const shop = useSelector((state) => state.shop);
  const shopify = useAppBridge();

  const tabs = [
    // {
    //   id: "tikTok",
    //   content: "TikTok",
    //   panelID: "tikTok",
    // },
    // {
    //   id: "instagram",
    //   content: "Instagram",
    //   panelID: "instagram",
    // },
    // {
    //   id: "youtube",
    //   content: "Youtube Shorts",
    //   panelID: "youtube",
    // },
    {
      id: "upload",
      content: "Upload",
      panelID: "upload",
    },
  ];
  const tabComponents = [
    // <SourceUpload source="tiktok" step={step} setStep={setStep} />,
    // <SourceUpload source="youtube" step={step} setStep={setStep} />,
    // <SourceUpload step={step} setStep={setStep} source="instagram" />,
    <UploadVideo
      shopify={shopify}
      step={step}
      setStep={setStep}
      shop={shop}
      active={active}
    />,
  ];

  const primarySourceUploadAction = {
    content: "Import videos",
    onAction: () => {
      setStep(2);
    },
  };
  const secondarySourceUploadAction = {
    content: "Back",
    onAction: () => {
      setStep(0);
    },
  };

  useEffect(() => {
    setStep(0);
  }, [selectedTab, active]);

  return (
    <div style={{ height: "500px" }}>
      <Frame>
        <Modal
          size="large"
          open={active}
          title="Add New Video"
          onClose={() => {
            if (step > 0) {
              getFirstPage();
            }
            onClose();
            setStep(0);
          }}
          primaryAction={
            
            step === 1 && selectedTab !== 1 ? primarySourceUploadAction : null
          }
          secondaryActions={
            step === 1 && selectedTab !== 1 ? secondarySourceUploadAction : null
          }
        >
          <Modal.Section>
            <div className="IronPop-AddNewVideoModal">
              <Tabs
                tabs={tabs}
                selected={selectedTab}
                onSelect={(num) => setSelectedTab(num)}
              >
                {tabComponents[selectedTab]}
              </Tabs>
            </div>
          </Modal.Section>
        </Modal>
      </Frame>
    </div>
  );
};
export default AddNewVideoModal;
