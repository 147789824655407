/* eslint-disable react-hooks/exhaustive-deps */
import _ from "lodash";
import React, { useEffect, useState } from "react";
import {
  ActionList,
  Button,
  Checkbox,
  Popover,
  SkeletonDisplayText,
} from "@shopify/polaris";
import { useAppBridge } from "@shopify/app-bridge-react";
import { useSelector } from "react-redux";
import { MenuHorizontalIcon } from "@shopify/polaris-icons";
import VideoRow from "./video-item";
import NoData from "./no-data";
import {
  PUBLISHED,
  UNPUBLISHED,
  VIDEO_LIBRARY_COLUMN,
} from "../../../common/constants/video-library";
import videoLibraryApi from "../../../apis/video-library-api";
import ConfirmDeleteModal from "../../../common/components/modal/confirm-modal/ConfirmDeleteModal";
import ConfirmActionModal from "../../../common/components/modal/confirm-modal/ConfirmActionModal";

const ListVideos = ({ videos = [], setVideos, loading, totalAll = 0 }) => {
  const shopify = useAppBridge();
  const shop = useSelector((state) => state.shop);

  const [selectedList, setSelectedList] = useState([]);
  const [popoverAction, setPopoverAction] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [updateSuccess, setUpdateSuccess] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [deleteAllModal, setDeleteAllModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);

  const isChecked = selectedList.length > 0;
  const handleCheck = (checked) => {
    if (checked) {
      const _selectedList = videos.map((v) => v.id);
      setSelectedList(_selectedList);
    } else {
      setSelectedList([]);
    }
  };

  const onDeleteMany = async (ids = []) => {
    setDeleting(true);
    const shopifyVideoIds = videos
      .filter((v) => ids.includes(v.id))
      .map((v) => v.shopifyVideoId);
    try {
      await videoLibraryApi.deleteVideo(
        shop.shop_uuid,
        ids,
        shopifyVideoIds
      );
      shopify.toast.show("Data deleted successfully!");
      const filterList = videos.filter((v) => !ids.includes(v.id));
      setDeleting(false);
      setVideos(filterList);
    } catch (error) {
      console.error(error);
      setDeleting(false);
    }
  };
  const onUpdateMany = async (ids, props) => {
    setUpdating(true);
    setUpdateSuccess(false);
    try {
      await videoLibraryApi.updateManyVideo(shop.shop_uuid, ids, props);
      shopify.toast.show("Data deleted successfully!");
      const filterList = videos.map((video) => {
        if (ids.includes(video.id)) {
          const newVideo = {
            ...video,
            ...props,
          };
          return newVideo;
        }
        return video;
      });
      setUpdating(false);
      setUpdateSuccess(true);
      setVideos(filterList);
    } catch (error) {
      console.error(error);
      setUpdating(false);
    }
  };

  const activator = (
    <span className="group_btn">
      <Button
        icon={MenuHorizontalIcon}
        onClick={() => setPopoverAction(true)}
      />
    </span>
  );

  const menuActions = [
    {
      content: <p style={{ paddingRight: "4px" }}>Mark as Active</p>,
      id: PUBLISHED,
      onAction: () => {
        setSelectedStatus(PUBLISHED);
        setConfirmModal(true);
        setPopoverAction(false);
      },
    },
    {
      content: <p style={{ paddingRight: "4px" }}>Mark as Inactive</p>,
      id: UNPUBLISHED,
      onAction: () => {
        setSelectedStatus(UNPUBLISHED);
        setConfirmModal(true);
        setPopoverAction(false);
      },
    },
  ];

  useEffect(() => {
    if (!updating && updateSuccess === true) {
      setConfirmModal(false);
      setSelectedList([]);
    }
  }, [updating]);

  useEffect(() => {
    if (!deleting) {
      setDeleteAllModal(false);
      setSelectedList([]);
    }
  }, [deleting]);

  return (
    <table className="Polaris-DataTable__Table IronPop-video-table">
      <thead>
        <tr style={{ backgroundColor: `${isChecked ? "#e3e3e3" : "unset"}` }}>
          {!isChecked ? (
            _.map(VIDEO_LIBRARY_COLUMN, (column, index) => (
              <td
                style={{
                  width: `${column.width}%`,
                  textAlign: `${column.textAlign}`,
                }}
                key={`heading-${column.value}`}
                className={`Polaris-DataTable__Cell Polaris-DataTable__Cell--header`}
              >
                {column.value !== "check" ? (
                  <div style={{ fontWeight: "600" }} data-tut={column.value}>
                    {column.value === "video"
                      ? column.title + ` (${videos?.length || 0})`
                      : column.title}
                  </div>
                ) : (
                  <div style={{ marginLeft: "-10px" }}>
                    <Checkbox
                      disabled={videos?.length <= 0}
                      checked={isChecked}
                      onChange={handleCheck}
                    />
                  </div>
                )}
              </td>
            ))
          ) : (
            <>
              <td
                style={{
                  width: `${VIDEO_LIBRARY_COLUMN[0].width}%`,
                }}
                key={`heading-${VIDEO_LIBRARY_COLUMN[0].value}`}
                className={`Polaris-DataTable__Cell Polaris-DataTable__Cell--header`}
              >
                <div>
                  <Checkbox checked={isChecked} onChange={handleCheck} />
                </div>
              </td>
              <td style={{ width: "100%" }} colSpan={5}>
                <div className="delete-row">
                  <div>{`${selectedList.length} selected`}</div>
                  <div className="actions">
                    <div>
                      <Button
                        onClick={() => setDeleteAllModal(true)}
                        tone="critical"
                      >
                        Delete
                      </Button>
                    </div>
                    <div>
                      <Popover
                        active={popoverAction}
                        activator={activator}
                        onClose={() => setPopoverAction(false)}
                      >
                        <Popover.Pane>
                          <div className="PopoverDeleteActionList-video">
                            <ActionList items={menuActions} />
                          </div>
                        </Popover.Pane>
                      </Popover>
                    </div>
                  </div>
                </div>
              </td>
            </>
          )}
        </tr>
      </thead>
      <tbody>
        {loading ? (
          <>
            {[
              "1-loading",
              "2-loading",
              "3-loading",
              "4-loading",
              "5-loading",
            ].map((item) => {
              return (
                <tr style={{ width: "100%" }} key={item}>
                  {_.map(VIDEO_LIBRARY_COLUMN, (column, index) => (
                    <td
                      style={{
                        width: `${column.width}%`,
                      }}
                      key={`${item}-${index}`}
                    >
                      <SkeletonDisplayText size="small" />
                    </td>
                  ))}
                </tr>
              );
            })}
          </>
        ) : videos?.length > 0 ? (
          <>
            {_.map(videos, (item, index) => (
              <VideoRow
                keyVideo={index}
                onDelete={onDeleteMany}
                deleting={deleting}
                indexItem={index}
                item={item}
                shopify={shopify}
                selectedList={selectedList}
                setSelectedList={setSelectedList}
                shop={shop}
                videos={videos}
                setVideos={setVideos}
              />
            ))}
          </>
        ) : (
          <tr style={{ width: "100%" }}>
            <td colSpan={6}>
              <NoData total={totalAll} />
            </td>
          </tr>
        )}
      </tbody>
      <ConfirmDeleteModal
        active={deleteAllModal}
        deleting={deleting}
        onClose={() => setDeleteAllModal(false)}
        title={"Confirm delete"}
        content={`Are you sure to delete ${selectedList?.length} video`}
        primaryAction={() => onDeleteMany(selectedList)}
      />
      <ConfirmActionModal
        active={confirmModal}
        loading={updating}
        onClose={() => setConfirmModal(false)}
        title={"Confirm "}
        content={`Are you sure to mask as ${
          selectedStatus === UNPUBLISHED ? "Inactive" : "Active"
        } for ${
          selectedList?.length > 1
            ? `${selectedList?.length} videos`
            : `${selectedList?.length} video`
        }`}
        primaryAction={() =>
          onUpdateMany(selectedList, { fileStatus: selectedStatus })
        }
      />
    </table>
  );
};

export default ListVideos;
