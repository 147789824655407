/* eslint-disable react-hooks/exhaustive-deps */
import _ from "lodash";
import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  BlockStack,
  Button,
  DropZone,
  Icon,
  InlineStack,
  ProgressBar,
  Text,
} from "@shopify/polaris";
import { FileIcon, DeleteIcon } from "@shopify/polaris-icons";
import videoLibraryApi from "../../../apis/video-library-api";
import {
  PROCESSING,
  PUBLISHED,
  UNPUBLISHED,
} from "../../../common/constants/video-library";

const LocalUpload = ({ active, step, setStep, shop, shopify }) => {
  let intervalId = null;
  const [files, setFiles] = useState(null);
  // status = none | processing | ready;
  const [errorImport, setErrorImport] = useState(null);
  const [progresses, setProgresses] = useState([]);
  const shouldContinueRef = useRef(true); // Ref to track if polling should continue

  // process after upload vide
  // call api get upload status
  // if status !== process => go to step 03
  const processAfterUpload = async (uploadedData) => {
    shouldContinueRef.current = true;
    intervalId = setInterval(async () => {
      if (!shouldContinueRef.current) {
        clearInterval(intervalId); // Clear the interval if polling is stopped
        return;
      }
      uploadedData?.forEach(async (info, index) => {
        if (!shouldContinueRef.current) return; // Skip processing if polling is stopped
        const processStatus = await videoLibraryApi.getVideoStatus(
          shop.shop_uuid,
          {
            shop: shop.id,
            id: info?.id,
            // fileName: files[0]?.name,
          }
        );
        const _status = _.get(processStatus, "data.fileStatus", PROCESSING);
        setProgresses((preProgresses) => {
          const newProgresses = [...preProgresses];
          let newProgress = newProgresses[index] + 20;
          if (newProgress > 100) {
            newProgress = 99;
          }
          if (_status === PUBLISHED || _status === UNPUBLISHED) {
            newProgress = 100;
          }
          newProgresses[index] = newProgress;
          return newProgresses;
        });
      });
    }, 2000); // Poll every 2 seconds

    // Cleanup: Clear interval on component unmount
    return () => {
      clearInterval(intervalId);
      shouldContinueRef.current = false;
    };
  };

  useEffect(() => {
    const findProcessing = progresses.find((p) => p !== 100);
    if (
      !findProcessing &&
      progresses?.length > 0 &&
      shouldContinueRef.current
    ) {
      clearInterval(intervalId); // Stop further processing
      shouldContinueRef.current = false;
      intervalId = null;
      setFiles(null);
      shopify.toast.show("Video added successfully");
      if (step !== 2) {
        setStep(2);
      }
    }
  }, [progresses]);

  useEffect(() => {
    if (!active) {
      // Stop further processing
      shouldContinueRef.current = false;
      clearInterval(intervalId); // Stop interval if modal is closed
    }

    return () => {
      // Stop further processing
      shouldContinueRef.current = false;
      clearInterval(intervalId);
    };
  }, [active]);

  const handleDropZoneDrop = useCallback(
    async (_dropFiles, acceptedFiles, _rejectedFiles) => {
      const validTypes = ["video/mp4", "video/webm", "video/avi", "video/ogg"];
      const maxSize = 50 * 1024 * 1024;
      const uploadedFiles = acceptedFiles;
      if (_rejectedFiles?.length > 0) {
        return;
      }

      // validate files
      const validFiles = acceptedFiles.filter((file) => {
        if (!validTypes.includes(file.type)) {
          setErrorImport(
            `File ${file?.name} is not a valid type. Only mp4, webm, avi and ogg are allowed.`
          );
          return false;
        }
        if (file.size > maxSize) {
          setErrorImport(`File ${file?.name} exceeds the size limit of 50MB.`);
          return false;
        }
        return true;
      });

      if (validFiles.length === acceptedFiles.length) {
        setFiles(uploadedFiles);
      }
    },
    []
  );

  const removeFile = (indexToRemove) => {
    let _files = files.filter((_, index) => index !== indexToRemove);
    setFiles(_files);
  };

  const handleImportSelectedVideos = async () => {
    const sampleData = Array.from({ length: files?.length }, () => 25);
    setProgresses(sampleData);
    setStep(1);
    const response = await videoLibraryApi.uploadVideo(shop.shop_uuid, {
      shop: shop.id,
      files,
    });
    const data = _.get(response, "data.data", null);
    processAfterUpload(data);
  };

  const fileUpload = (
    <DropZone.FileUpload
      actionTitle="Add file"
      actionHint="Click or drop videos here to upload. Accept .mp4, .webm, .avi, .ogg."
    />
  );

  const existedFiles = (
    <div className="IronPop-chooseOtherFile">
      <DropZone.FileUpload
        labelHidden={true}
        actionTitle="Choose other file"
        actionHint="Click or drop videos here to upload. Accept .mp4, .webm, .avi, .ogg."
      />
    </div>
  );

  const SelectFileView = (
    <div className="select-view">
      <BlockStack gap="1000">
        {files && files.length > 0 && (
          <BlockStack gap={500}>
            {files?.map((file, index) => {
              return (
                <div key={`file-item-${index}`} className="item">
                  <InlineStack blockAlign="center" align="space-between">
                    <InlineStack gap="50">
                      <Icon source={FileIcon} />
                      <p>{file?.name}</p>
                    </InlineStack>
                    <Button
                      variant="plain"
                      icon={DeleteIcon}
                      onClick={() => removeFile(index)}
                    />
                  </InlineStack>
                </div>
              );
            })}
          </BlockStack>
        )}
        <BlockStack gap={500}>
          <div className="upload">
            <DropZone
              onDrop={handleDropZoneDrop}
              accept="video/mp4, video/webm, video/avi, video/ogg"
              variableHeight
              allowMultiple={true}
            >
              {files && files.length > 0 ? existedFiles : fileUpload}
            </DropZone>
            {errorImport && <Text tone="critical"> {errorImport} </Text>}
          </div>

          <div className="helper-text">
            <p>
              The recommended video ratio is 9:16, with a maximum size of 50 MB.
            </p>
            {/* <p>You can upload up to 5 videos at a time.</p> */}
          </div>
        </BlockStack>
        <div className="action">
          <Button
            disabled={!files || files?.length === 0}
            variant="primary"
            onClick={() => handleImportSelectedVideos()}
          >
            Import selected videos
          </Button>
        </div>
      </BlockStack>
    </div>
  );
  const ProgressView = (
    <div className="process-view">
      <BlockStack gap={1000}>
        {files && files.length > 0 && (
          <BlockStack gap={500}>
            {files?.map((file, index) => {
              return (
                <div key={`upload-progress-item-${index}`} className="item">
                  <InlineStack blockAlign="start" gap="10">
                    <Icon source={FileIcon} />
                    <BlockStack gap={100}>
                      <p>{file?.name}</p>
                      <ProgressBar
                        progress={
                          progresses[index] && progresses[index] > 0
                            ? progresses[index]
                            : 25
                        }
                        tone="primary"
                        size="small"
                      />
                    </BlockStack>
                  </InlineStack>
                </div>
              );
            })}
          </BlockStack>
        )}

        <div className="action">
          <Button variant="primary" loading={true} />
        </div>
      </BlockStack>
    </div>
  );
  const UploadSuccessView = (
    <div className="success-view">
      <BlockStack gap={200}>
        <p>Your videos are uploaded successfully!</p>
        <p>
          We’re currently processing your videos. This could take some time to
          complete. If you'd like, you can close this dialog box and continue
          working or you can upload new videos
        </p>
      </BlockStack>
      <div className="action">
        <Button
          variant="primary"
          onClick={() => {
            setStep(0);
            setFiles(null);
            setErrorImport();
          }}
        >
          Upload New Video
        </Button>
      </div>
    </div>
  );
  const viewComponents = [SelectFileView, ProgressView, UploadSuccessView];

  return <div className="local-upload">{viewComponents[step]}</div>;
};
export default LocalUpload;
