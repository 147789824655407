/* eslint-disable react-hooks/exhaustive-deps */
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button, Card, InlineStack, Page, Pagination, Tabs, Tag } from "@shopify/polaris";

import "./styles.css";
import VideosList from "../../features/video-library/management/videos-list";
import FilterBar from "../../features/video-library/management/filter-bar";
import AddNewVideoModal from "../../features/video-library/new/add-video-modal";
import { SORT_OPTIONS, VIDEO_SOURCE_TYPES, VIDEO_STATUS } from "../../common/constants/video-library";
import videoLibraryApi from "../../apis/video-library-api";

const NUMBER_ROW_PER_PAGE = 10;

const Library = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [videos, setVideos] = useState([]);
  const [total, setTotal] = useState(0);
  const [totalAll, setTotalAll] = useState(0);
  const [loading, setLoading] = useState(false);
  const [queries, setQueries] = useState({});
  const [openAddModal, setOpenAddModal] = useState(false);

  const shop = useSelector((state) => state.shop);

  const getList = async (allQueries) => {
    try {
      const _queries = {
        ...allQueries,
      };
      setLoading(true);
      const response = await videoLibraryApi.getListVideo(shop.shop_uuid, _queries);
      setLoading(false);
      const data = _.get(response, "data", []);
      const totalAll = data?.totalAll || 0;
      const total = data?.total || 0;
      const _videos = data?.list || [];
      setVideos(_videos);
      setTotal(total);
      setTotalAll(totalAll);
    } catch (error) {
      console.error(error);
      setVideos([]);
      setTotal(0);
    }
  };

  const getFirstPage = async () => {
    setSelectedTab(0);
    const _queries = {
      shop: shop?.id,
      skip: 0,
      limit: NUMBER_ROW_PER_PAGE,
    };
    setQueries(_queries);
    await getList(_queries);
  };

  const tabs = [
    {
      id: "all-videos",
      content: "All",
      panelID: "all-videos",
    },
    {
      id: "tagged-videos",
      content: "Tagged videos",
      panelID: "tagged-videos",
    },
    {
      id: "Untagged videos",
      content: "Untagged videos",
      panelID: "untagged-videos",
    },
  ];

  const disambiguateLabel = (type) => {
    switch (type) {
      case "fileStatus": {
        const statusObj = _.find(VIDEO_STATUS, (item) => item.value === queries.fileStatus);

        if (_.isEmpty(statusObj) || !statusObj) return null;
        return `Status: ${statusObj.label}`;
      }
      case "source": {
        const sourceObj = _.find(VIDEO_SOURCE_TYPES, (item) => item.name === queries.source);
        if (_.isEmpty(sourceObj) || !sourceObj) return null;
        return `Source: ${sourceObj.label}`;
      }
      case "sortBy": {
        const sortObj = _.find(SORT_OPTIONS, (item) => item.value === queries.sortBy);
        if (_.isEmpty(sortObj) || !sortObj) return null;
        return `Sort by: ${sortObj.label}`;
      }
      default: {
        return;
      }
    }
  };

  // Render apply filter for filter
  const renderApplyFilter = () => {
    const appliedFilters = [];
    if (!_.isEmpty(queries.fileStatus)) {
      const key = "fileStatus";
      appliedFilters.push({
        key,
        label: disambiguateLabel(key),
        onRemove: () => {
          const _queries = {
            ...queries,
          };
          delete _queries.fileStatus;
          setQueries(_queries);
          getList(_queries);
        },
      });
    }
    if (!_.isEmpty(queries.source)) {
      const key = "source";
      appliedFilters.push({
        key,
        label: disambiguateLabel(key),
        onRemove: () => {
          const _queries = {
            ...queries,
          };
          delete _queries.source;
          setQueries(_queries);
          getList(_queries);
        },
      });
    }
    if (!_.isEmpty(queries.sortBy)) {
      const key = "sortBy";
      appliedFilters.push({
        key,
        label: disambiguateLabel(key),
        onRemove: () => {
          const _queries = {
            ...queries,
          };
          delete _queries.sortBy;
          setQueries(_queries);
        },
      });
    }

    if (appliedFilters.length <= 0) return <></>;
    const tagMarkUp = _.map(appliedFilters, (appliedFilter) => {
      const { key, label, onRemove } = appliedFilter;
      return (
        <Tag key={key} onRemove={onRemove}>
          {label}
        </Tag>
      );
    });

    return (
      <div className="tags-list">
        <InlineStack gap={200}>{tagMarkUp}</InlineStack>
      </div>
    );
  };

  const getPreviousPage = async () => {
    const previousSkip = queries.skip - NUMBER_ROW_PER_PAGE;
    if (previousSkip < 0) return;
    const _queries = {
      ...queries,
      skip: previousSkip,
    };
    setQueries(_queries);
    await getList(_queries);
  };

  const getNextPage = async () => {
    const nextSkip = queries.skip + NUMBER_ROW_PER_PAGE;
    // Prevent multiple click that the skip number is bigger than total
    if (nextSkip >= total) return;
    const _queries = {
      ...queries,
      skip: nextSkip,
    };
    setQueries(_queries);
    await getList(_queries);
  };

  useEffect(() => {
    let _queries = {
      ...queries,
      shop: shop?.id,
      skip: 0,
      limit: NUMBER_ROW_PER_PAGE,
    };
    // get all data for first tab
    if (selectedTab === 0) {
      delete _queries.tagProduct;
    } else {
      _queries = {
        ..._queries,
        tagProduct: selectedTab === 1,
      };
    }
    setQueries(_queries);
    getList(_queries);
  }, [selectedTab]);

  return (
    <div className="IronPop-Page-VideoLibrary">
      <Page
        title="Video Library"
        primaryAction={
          <Button id="btn-add-new" submit variant="primary" onClick={() => setOpenAddModal(true)}>
            Add New Video
          </Button>
        }
      >
        <br />
        <Card>
          <FilterBar queries={queries} changeQueries={(data) => setQueries(data)} getList={getList} />

          <Tabs tabs={tabs} selected={selectedTab} onSelect={(n) => setSelectedTab(n)}>
            <div className="IronPop-library">
              {renderApplyFilter()}
              <VideosList totalAll={totalAll} videos={videos} setVideos={setVideos} loading={loading} />
            </div>
            {(queries.skip > 0 || queries.skip + NUMBER_ROW_PER_PAGE < total) && (
              <Pagination
                hasPrevious={queries.skip > 0}
                onPrevious={getPreviousPage}
                hasNext={queries.skip + NUMBER_ROW_PER_PAGE < total}
                onNext={getNextPage}
              />
            )}
          </Tabs>
        </Card>
        {openAddModal && (
          <AddNewVideoModal active={openAddModal} getFirstPage={getFirstPage} onClose={() => setOpenAddModal(false)} />
        )}
      </Page>
    </div>
  );
};

export default Library;
