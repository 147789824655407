import React, { useEffect, useState } from "react";
import PageLoading from "./page-loading";
import { useSelector } from "react-redux";
import { SHOPIFY_SCOPES_LIST } from "../../constants/common";
import { buildUpdatePermissionURL } from "../../helper";

const AppRoute = ({ ready, children }) => {
  const [enoughPermission, setEnoughPermission] = useState(false);
  const shop = useSelector((state) => state.shop);

  useEffect(() => {
    if (shop.connected && shop.authToken) {
      if (!SHOPIFY_SCOPES_LIST.every((item) => shop.accessScopes.includes(item))) {
        window.top.location.href = buildUpdatePermissionURL(shop.id);
      } else {
        setEnoughPermission(true);
      }
    }
  }, [shop]);

  if (!ready || !enoughPermission) return <PageLoading />;

  return children;
};

export default AppRoute;
