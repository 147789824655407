/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { NavMenu } from "@shopify/app-bridge-react";
import { Link, Route, Routes, useLocation } from "react-router-dom";
import HomePage from "./pages/home";
import { AppProvider } from "@shopify/polaris";
import PopTypesPage from "./pages/pop-types";
import Auth from "./pages/auth";
import { useDispatch, useSelector } from "react-redux";
import { SHOPIFY_API_KEY } from "./common/constants/common";
import { getShopInfo } from "./stores/slices/shopSlice";
import queryString from "query-string";
import DesignPage from "./pages/design";
import AppRoute from "./common/components/route/app-route";
import NotFoundPage from "./common/components/route/not-found";
import SettingsPage from "./pages/settings";
import OrderCount from "./pages/order-count";
import SalesNoti from "./pages/sales-noti";
import AppEmbeddedInline from "./features/common/app-embedded-inline";
import VideoLibrary from "./pages/video-library";
import { waitFor } from "./common/utils";

const App = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [ready, setReady] = useState(!!window.shopify);

  const shop = useSelector((state) => state.shop);
  const settings = useSelector((state) => state.settings);
  const { data: settingsData = {} } = settings;
  const { appEmbedEnable = false } = settingsData;
  const queries = queryString.parse(location.search);

  useEffect(() => {
    dispatch(getShopInfo(queries));
  }, []);

  useEffect(() => {
    if (shop.connected && shop.authToken && !ready) {
      const meta = document.createElement("meta");
      meta.name = "shopify-api-key";
      meta.content = SHOPIFY_API_KEY;
      const script = document.createElement("script");
      script.async = false;

      script.src = "https://cdn.shopify.com/shopifycloud/app-bridge.js";
      document.getElementsByTagName("head")[0].appendChild(meta);
      document.getElementsByTagName("head")[0].appendChild(script);

      const waitForLoadShopifyAppBridge = async () => {
        await waitFor(500);
        setReady(true);
      };
      waitForLoadShopifyAppBridge();
    }
  }, [shop]);

  return (
    <div>
      <div>
        <AppProvider>
          {!appEmbedEnable && ready && settings.loaded && <AppEmbeddedInline shop={shop} />}
          <Routes>
            <Route path="/auth" element={<Auth />} />
            <Route path="/" element={<HomePage />} />
            <Route
              path="/pop-types"
              element={
                <AppRoute ready={ready}>
                  <PopTypesPage />
                </AppRoute>
              }
            />
            <Route
              path="/pop-types/order-count"
              element={
                <AppRoute ready={ready}>
                  <OrderCount />
                </AppRoute>
              }
            />
            <Route
              path="/pop-types/sales-noti"
              element={
                <AppRoute ready={ready}>
                  <SalesNoti />
                </AppRoute>
              }
            />
            <Route
              path="/design"
              element={
                <AppRoute ready={ready}>
                  <DesignPage />
                </AppRoute>
              }
            />
            <Route
              path="/settings"
              element={
                <AppRoute ready={ready}>
                  <SettingsPage />
                </AppRoute>
              }
            />
            <Route
              path="/video-library"
              element={
                <AppRoute ready={ready}>
                  <VideoLibrary />
                </AppRoute>
              }
            />
            <Route path="/*" element={<NotFoundPage />} />
          </Routes>

          {ready && (
            <NavMenu>
              <Link to="/" rel="home">
                Home
              </Link>
              <Link
                to="/pop-types"
                rel="pop-types"
                className={location.pathname.startsWith("/pop-types") ? "active" : ""}
              >
                Pop types
              </Link>
              <Link to="/design" rel="design">
                Design
              </Link>
              <Link to="/settings" rel="setting">
                General settings
              </Link>
              <Link to="/video-library" rel="video-library">
                Video library
              </Link>
            </NavMenu>
          )}
        </AppProvider>
      </div>
    </div>
  );
};

export default App;
