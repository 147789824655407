import _ from "lodash";
import React, { useState } from "react";
import { Modal } from "@shopify/polaris";
import { useDispatch, useSelector } from "react-redux";
import settingsApi from "../../../apis/settings-api";
import { updateStateSettings } from "../../../stores/slices/settingsSlice";
import { IMAGE_HOMEPAGE_URL } from "../../../common/constants/home";

const GuideModal = ({
  active,
  onOpen,
  onClose,
  enableAppEmbed,
  onCloseAllPopup,
  setIsUpdatedSettings,
}) => {
  const [step, setStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const shop = useSelector((state) => state.shop);
  const dispatch = useDispatch();

  const onVerify = async () => {
    // call API verify enabled iron-pop
    try {
      setLoading(true);
      const data = await settingsApi.get(shop.shop_uuid);
      const appEmbedEnable = _.get(data, "data.appEmbedEnable", false);
      dispatch(updateStateSettings({ settings: { appEmbedEnable } }));
      setIsUpdatedSettings(true);
      setLoading(false);
      onClose();
      if (!appEmbedEnable) {
        setStep(1);
      } else setStep(2);
      onOpen();
    } catch (error) {
      setLoading(false);
    }
  };

  const onEnable = () => {
    // open new tab
    enableAppEmbed();
  };

  const step01Content = (
    <div className="IronPop-guideSetupModal">
      <div>
        <p>
          Please hit “Enable IronPop” and then click “Save” in the Theme editor
          to make it live.
        </p>
        <div>
          <p>
            If you want to test the app on other themes, follow these steps:
          </p>
          <p>
            1. Go to{" "}
            <span style={{ color: "#0056d4" }}>
              Sales Channel -&gt; Online Store
            </span>
          </p>
          <p>2. Click “Customize” button in the theme</p>
          <p>3. Go to “App Embeds”</p>
          <p>
            4. Find <b>IronPop: Sales Popup </b>app embed and enable it
          </p>
          <p>5. Click “Save”</p>
        </div>
      </div>
      <div className="image">
        <img
          alt="welcome-setup-img"
          src={`${IMAGE_HOMEPAGE_URL}/welcome-setup-img.svg`}
        />
      </div>
    </div>
  );
  const step02Content = (
    <div>IronPop is not enabled in your live theme yet! Please try again.</div>
  );
  const step03Content = (
    <div>IronPop has been successfully installed in your live theme!</div>
  );

  const renderContent = [step01Content, step02Content, step03Content];

  return (
    <Modal
      open={active}
      onClose={onCloseAllPopup}
      title="Enable IronPop in the Shopify theme!"
      primaryAction={
        step === 2
          ? {
              content: "Close",
              onAction: onCloseAllPopup,
            }
          : {
              content: "Enable IronPop",
              onAction: onEnable,
            }
      }
      secondaryActions={
        step !== 2
          ? [
              {
                content: "Verify",
                onAction: onVerify,
                loading: loading,
              },
            ]
          : null
      }
    >
      <Modal.Section>{renderContent[step]}</Modal.Section>
    </Modal>
  );
};

export default GuideModal;
