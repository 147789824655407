import React, { useState } from "react";

import WelcomeModal from "./welcome";
import GuideModal from "./guide";
import { useDispatch } from "react-redux";
import { updateShopInfo } from "../../../stores/slices/shopSlice";

const WelcomeGuide = ({ shop, enableAppEmbed, setIsUpdatedSettings }) => {
  const [welcomeActive, setWelcomeActive] = useState(true);
  const [guideActive, setGuideActive] = useState(false);

  const dispatch = useDispatch();

  const onNext = () => {
    setWelcomeActive(false);
    setGuideActive(true);
  };

  const onCloseAllPopup = async () => {
    setWelcomeActive(false);
    setGuideActive(false);
    dispatch(
      updateShopInfo({ shopId: shop.id, data: { showWelcomePopup: false } })
    );
  };

  return (
    <>
      <WelcomeModal
        active={welcomeActive}
        onCloseAllPopup={onCloseAllPopup}
        onNext={onNext}
      />
      <GuideModal
        active={guideActive}
        setIsUpdatedSettings={setIsUpdatedSettings}
        onClose={() => setGuideActive(false)}
        onOpen={() => setGuideActive(true)}
        enableAppEmbed={enableAppEmbed}
        onCloseAllPopup={onCloseAllPopup}
      />
    </>
  );
};

export default WelcomeGuide;
