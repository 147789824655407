import React from "react";
import { Button, Modal } from "@shopify/polaris";

import { IMAGE_HOMEPAGE_URL } from "../../../common/constants/home";

const WelcomeModal = ({ active, onNext, onCloseAllPopup }) => {
  return (
    <Modal open={active} onClose={onCloseAllPopup}>
      <div className="IronPop-welcomeModal">
        <div>
          <h1>Welcome to IronPop 🎉!</h1>
          <p>
            Get ready to rocket your sales with our sales-boosting solutions!
          </p>
        </div>
        <img
          alt="welcome-ip"
          src={`${IMAGE_HOMEPAGE_URL}/welcome-guide-img.png`}
        />
        <div>
          <Button variant="primary" onClick={onNext}>
            Let’s get started!
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default WelcomeModal;
