export const SHOPIFY_REVIEW_URL = "https://apps.shopify.com/ironpop-sales-pop-up-proof#modal-show=WriteReviewModal";
export const SHOPIFY_API_KEY = process.env.REACT_APP_SHOPIFY_API_KEY;
export const SHOPIFY_SCOPES = process.env.REACT_APP_SHOPIFY_SCOPES;
export const SHOPIFY_SCOPES_LIST = process.env.REACT_APP_SHOPIFY_SCOPES.split(",");
export const SHOPIFY_EMBED_ID = process.env.REACT_APP_SHOPIFY_EMBED_ID;

export const IRON_POP_IMAGE_URL = "https://cdn.iron-pop.com";

export const ADMIN_URL = process.env.REACT_APP_ADMIN_URL;
export const INSTALL_CALLBACK = `${ADMIN_URL}/auth`;
export const UPDATE_PERMISSION_CALLBACK = `${ADMIN_URL}`;

export const MAX_VALUE_NUMBER = 999999;
