import _ from "lodash";
import axios from "axios";
import apiConfig from "./api-config.json";

const ADMIN_API_URL = process.env.REACT_APP_ADMIN_API_URL;

/**
 *  Create an instance of axios with the correct headers set
 * @param {string} type
 */
export const baseApi = (type) => {
  const headers = {
    "Content-Type": "application/json",
  };

  const api = axios.create({
    baseURL: getBaseUrl(type),
    headers,
  });

  return api;
};

/**
 * Make an API request to the given route with the given payload
 * @param {*} route
 * @param {*} payload
 * @returns
 */
export const request = async (route, payload, responseType = "json") => {
  const { params, queries, body } = payload;

  const routeConfig = getApiConfig(route) || {};

  const { method, path, type } = routeConfig;

  // Replace route params (e.g., /:shopuuid) with actual value from `params`
  let url = path;
  if (params) {
    for (const key in params) {
      url = url.replace(`:${key}`, encodeURIComponent(params[key]));
    }
  }

  // Create Axios request configuration
  const config = { method, url, params: queries, responseType };

  if (body && ["post", "put", "patch", "delete"].includes(method)) {
    config.data = body;
  }

  try {
    const clientApi = baseApi(type);
    return clientApi.request(config);
  } catch (error) {
    console.log("error", error.stack);
  }
};

export const uploadShopifyVideo = async (payload) => {
  const { params } = payload;
  const body = payload.body || {};

  const shop = body?.shop;
  const allFiles = body?.files;

  const fileInfos = allFiles?.map((f) => ({
    fileName: f?.name,
    fileSize: f?.size, // byte
    mimeType: f?.type, // video/mp4
  }));

  // Step01: register upload video to cnd shopify
  const response = await request("admin-api.video-library.staged-upload", {
    params,
    body: {
      shop,
      fileInfos,
    },
  });

  const data = response?.data?.data || [];
  // Step02: upload files that registered

  await Promise.all(
    data?.map(async (stagedUpload, index) => {
      const { url = "", parameters = [] } = stagedUpload || {};

      const videoFile = allFiles[index];
      const formData = new FormData();
      parameters.forEach((param) => formData.append(param.name, param.value));
      formData.append("file", videoFile);
      // Step02: upload video to cnd shopify that registered
      return await fetch(url, {
        method: "POST",
        body: formData,
      });
    })
  );

  //  step 03: update data in db
  const uploadFileInfos = allFiles.map((file, index) => ({
    fileName: file.name,
    resourceUrl: data[index]?.resourceUrl,
  }));

  const responseUploadData = await request(
    "admin-api.video-library.upload-video",
    {
      params,
      body: {
        shop: body.shop,
        fileInfos: uploadFileInfos,
      },
    }
  );

  return responseUploadData;
};

/**
 * Locate api config by given route
 * @param {string} route
 * @returns
 */
const getApiConfig = (route) => {
  const parts = route.split(".");
  const routeType = parts[0];
  const routeName = parts.pop();
  const routeGroup = parts.join(".");

  // Get route group that api belongs to
  const routes = _.get(apiConfig, routeGroup);

  // Get api details
  const api = _.find(routes, { name: routeName });

  return { ...api, type: routeType };
};

/**
 * Build main URL for the requested service
 * @param {string} type
 * @returns
 */
const getBaseUrl = (type) => {
  switch (type) {
    case "admin-api":
      return ADMIN_API_URL;
    default:
      return ADMIN_API_URL;
  }
};
