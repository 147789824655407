import { INSTALL_CALLBACK, SHOPIFY_API_KEY, SHOPIFY_SCOPES, UPDATE_PERMISSION_CALLBACK } from "../constants/common";
import { setObject } from "../utils";

export const buildURLPopupBlock = (appEmbedUuid) => {
  return `admin/themes/current/editor?context=apps&activateAppId=${appEmbedUuid}/popup-block`;
};

export const buildAuthURL = (shop) => {
  const authURL = `https://${shop}/admin/oauth/authorize?client_id=${SHOPIFY_API_KEY}&scope=${SHOPIFY_SCOPES}&redirect_uri=${INSTALL_CALLBACK}&state=ironpop@2024`;
  return authURL;
};

export const buildUpdatePermissionURL = (shop) => {
  const authURL = `https://${shop}/admin/oauth/authorize?client_id=${SHOPIFY_API_KEY}&scope=${SHOPIFY_SCOPES}&redirect_uri=${UPDATE_PERMISSION_CALLBACK}&state=ironpop@2024`;
  return authURL;
};

export const buildRedirectUrl = (shop) => {
  const redirectUrl = `https://admin.shopify.com/store/${shop.replace(".myshopify.com", "")}/apps/${SHOPIFY_API_KEY}`;
  return redirectUrl;
};

export const buildSettingsPayload = (shopuuid, settingsData, path = "", updateProps) => {
  const paths = path.length === 0 ? [] : path.split(".");

  return {
    shopuuid,
    settings: setObject(settingsData, paths, updateProps),
  };
};

export const buildUpdatePermissionRedirectPageKey = (domain) => {
  return `${domain}-permission-redirect-page`;
};

/**
 * Export to file
 */
export const downloadFile = (blob, fileName) => {
  // Create blob link to download
  const url = window.URL.createObjectURL(new Blob([blob]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", fileName);

  // Append to html page
  // $FlowFixMe
  document.body.appendChild(link);

  // Force download
  link.click();
  // Clean up and remove the link
  // $FlowFixMe
  link.parentNode.removeChild(link);
};

export const generateRandomString = (length) => {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"; // Define the character set
  let result = ""; // Initialize an empty string

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length); // Get a random index
    result += characters[randomIndex]; // Add the character at the random index to the result
  }

  return result;
};
