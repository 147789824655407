export const VIDEO_LIBRARY_COLUMN = [
  { value: "check", title: "", textAlign: "center", width: 5 },
  { value: "video", title: "Video", textAlign: "start", width: 35 },
  // { value: "revenue", title: "Revenue", textAlign: "start", width: 10 },
  { value: "status", title: "Status", textAlign: "center", width: 10 },
  { value: "createdAt", title: "Date added", textAlign: "center", width: 15 },
  {
    value: "products",
    title: "Tagged products",
    textAlign: "start",
    width: 20,
  },
  { value: "actions", title: "Actions", textAlign: "center", width: 15 },
];

export const VIDEO_SOURCE_TYPES = [
  {
    name: "instagram",
    label: "Instagram",
    url: "https://cdn.iron-pop.com/video-library/instagram-logo.svg",
  },
  {
    name: "youtube",
    label: "Youtube shorts",
    url: "https://cdn.iron-pop.com/video-library/youtube-logo.svg",
  },
  {
    name: "tiktok",
    label: "TikTok",
    url: "https://cdn.iron-pop.com/video-library/tiktok-logo.svg",
  },
  {
    name: "import",
    label: "Upload",
    url: "https://cdn.iron-pop.com/video-library/upload-icon.png",
  },
];

export const PUBLISHED = "PUBLISHED";
export const UNPUBLISHED = "UNPUBLISHED";
export const PROCESSING = "PROCESSING";

export const VIDEO_STATUS = [
  {
    label: "Published",
    value: PUBLISHED,
  },
  {
    label: "Unpublished",
    value: UNPUBLISHED,
  },
  {
    label: "Processing",
    value: PROCESSING,
  },
];

export const SORT_OPTIONS = [
  { value: "recent-added", label: "Recent added" },
  { value: "most-view", label: "Most viewed" },
  { value: "least-view", label: "Least viewed" },
];
