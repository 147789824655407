/* eslint-disable react-hooks/exhaustive-deps */
import _ from "lodash";
import { useEffect, useState } from "react";
import { Page, Text, Frame } from "@shopify/polaris";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "./styles.css";
import AppStatus from "../../features/home/app-status";
import Support from "../../features/home/support";
import SetupGuide from "../../features/home/setup-guide";
import WelcomeGuide from "../../features/home/welcome-guide";
import { SETUP_GUIDE } from "../../common/constants/home";
import { getSettings, updateSettings } from "../../stores/slices/settingsSlice";
import PageLoading from "../../common/components/route/page-loading";
import {
  SHOPIFY_EMBED_ID,
  SHOPIFY_REVIEW_URL,
  SHOPIFY_SCOPES_LIST,
} from "../../common/constants/common";
import queryString from "query-string";
import {
  buildAuthURL,
  buildSettingsPayload,
  buildUpdatePermissionURL,
  buildURLPopupBlock,
} from "../../common/helper";

const HomePage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [ready, setReady] = useState(false);
  const [isUpdatedSettings, setIsUpdatedSettings] = useState(false);
  const settings = useSelector((state) => state.settings);
  const shop = useSelector((state) => state.shop);
  const showWelcomePopup = _.get(shop, "showWelcomePopup", true);

  const queries = queryString.parse(location.search);

  useEffect(() => {
    if (shop.connected && !shop.authToken) {
      const authURL = buildAuthURL(queries.shop);
      window.location.href = authURL;
    }
  }, [shop]);

  useEffect(() => {
    if (shop.connected && shop.authToken) {
      if (!settings.loaded) {
        if (
          !SHOPIFY_SCOPES_LIST.every((item) => shop.accessScopes.includes(item))
        ) {
          window.top.location.href = buildUpdatePermissionURL(shop.id);
        } else {
          setReady(true);
          dispatch(getSettings(shop.shop_uuid));
        }
      } else {
        setReady(true);
      }
    }
  }, [shop]);

  const { data: settingsData = {} } = settings;
  const { appEmbedEnable, settingPopups = {} } = settingsData;
  const { homeSettings = {} } = settingPopups;
  const { isCustomizePopup, isReviewed } = homeSettings;

  const enableAppEmbed = () => {
    const handleURL = buildURLPopupBlock(SHOPIFY_EMBED_ID);
    window.open(`https://${shop.id}/${handleURL}`, "_blank");
  };

  const handleStepGuideClick = async (field, action) => {
    if (homeSettings[field]) return;

    dispatch(
      updateSettings(
        buildSettingsPayload(
          shop.shop_uuid,
          settingsData,
          "settingPopups.homeSettings",
          { [field]: true }
        )
      )
    );

    action();
  };

  const SetupGuideList = [
    {
      ...SETUP_GUIDE["app_embed"],
      id: "app_embed",
      completed: appEmbedEnable,
      actions: [
        {
          disabled: appEmbedEnable,
          content: "Activate the app embed",
          onClick: () => {
            enableAppEmbed();
          },
        },
      ],
    },
    {
      ...SETUP_GUIDE["customize_pop"],
      id: "customize_pop",
      completed: isCustomizePopup,
      actions: [
        {
          disabled: isCustomizePopup,
          content: "Customize notifications",
          onClick: () => {
            handleStepGuideClick("isCustomizePopup", () => {
              navigate("/pop-types");
            });
          },
        },
      ],
    },
    {
      ...SETUP_GUIDE["review_app"],
      id: "review_app",
      completed: isReviewed,
      actions: [
        {
          disabled: isReviewed,
          content: "Give Us a Review",
          onClick: () => {
            handleStepGuideClick("isReviewed", () => {
              window.open(SHOPIFY_REVIEW_URL, "_blank");
            });
          },
        },
      ],
    },
  ];

  return shop.authToken && ready ? (
    <Page>
      <form>
        <div className="IronPop-home">
          <Text variant="headingLg" as="h1">
            Welcome to IronPop!
          </Text>
          <AppStatus
            loaded={settings.loaded}
            embedEnabled={appEmbedEnable}
            enableAppEmbed={enableAppEmbed}
          />
          <Support />
          <SetupGuide SetupGuideList={SetupGuideList} />
        </div>
      </form>
      {showWelcomePopup &&
        settings.loaded &&
        !(appEmbedEnable && !isUpdatedSettings) && (
          <WelcomeGuide
            enableAppEmbed={enableAppEmbed}
            shop={shop}
            setIsUpdatedSettings={setIsUpdatedSettings}
          />
        )}
    </Page>
  ) : (
    <Frame>
      <PageLoading />
    </Frame>
  );
};

export default HomePage;
