/* eslint-disable react-hooks/exhaustive-deps */
import _ from "lodash";
import moment from "moment";
import React, { useState } from "react";
import Toggle from "../../../common/components/custom/toggle";
import {
  ActionList,
  Badge,
  Button,
  Checkbox,
  Icon,
  Link,
  Popover,
  Thumbnail,
  Tooltip,
} from "@shopify/polaris";
import {
  LinkIcon,
  MenuHorizontalIcon,
  ViewIcon,
  EditIcon,
  SaveIcon,
  DuplicateIcon,
  DeleteIcon,
} from "@shopify/polaris-icons";
import SelectProductModal from "../../../common/components/modal/product/ProductModal";
import VideoModal from "../../../common/components/modal/video/VideoViewModal";
import ConfirmDeleteModal from "../../../common/components/modal/confirm-modal/ConfirmDeleteModal";
import EditModal from "./edit-modal";

import {
  VIDEO_SOURCE_TYPES,
  VIDEO_LIBRARY_COLUMN,
  PROCESSING,
  PUBLISHED,
  UNPUBLISHED,
} from "../../../common/constants/video-library";
import videoLibraryApi from "../../../apis/video-library-api";

const VideoItem = ({
  indexItem,
  item,
  keyVideo,
  shopify,
  deleting,
  onDelete,
  shop,
  selectedList,
  setSelectedList,
  videos,
  setVideos,
}) => {
  let timerUpdate = null;

  const selectedProducts = _.get(item, "products", []);
  const [popoverAction, setPopoverAction] = useState(false);
  const [openVideo, setOpenVideo] = useState(false);
  const [openProductModal, setOpenProductModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);

  const handleDownload = async (name, url) => {
    try {
      const response = await fetch(url); // Fetch the video file
      const blob = await response.blob(); // Convert it to a Blob
      const link = document.createElement("a"); // Create a download link

      // Create a URL for the Blob and assign it to the link
      const _url = URL.createObjectURL(blob);
      link.href = _url;
      link.download = `${name}.mp4`; // Specify the download filename

      document.body.appendChild(link);
      link.click(); // Trigger the download
      document.body.removeChild(link);

      // Clean up after the download
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Download failed:", error);
      window.open(url, "_blank"); // Fallback if the download fails
    }
  };

  const handleDelete = (ids = []) => {
    onDelete(ids);
    setOpenDeleteModal(false);
  };

  const copyClipBoard = (copyText) => {
    try {
      const inputText = document.createElement("textarea");
      inputText.value = copyText;
      document.body.appendChild(inputText);
      inputText.select();
      document.execCommand("copy");
      document.body.removeChild(inputText);
      shopify.toast.show("Copied");
    } catch (error) {}
  };

  const handleUpdateItem = (data) => {
    clearTimeout(timerUpdate);
    // Invoke search request after 500ms
    timerUpdate = setTimeout(async () => {
      try {
        await videoLibraryApi.updateVideo(shop.shop_uuid, item.id, data);
        shopify.toast.show("Data updated successfully!");
      } catch (error) {
        console.error(error);
      }
    }, 500);
    const _videos = _.cloneDeep(videos);

    if (_videos[indexItem]) {
      Object.keys(data).forEach((dataKey) => {
        _.set(_videos, `[${indexItem}].${dataKey}`, data[dataKey]);
      });
    }

    setVideos(_videos);
  };

  const handleDuplicate = async () => {
    try {
      const response = await videoLibraryApi.duplicateVideo(
        shop.shop_uuid,
        item.id
      );
      const duplicateData = response?.data || {};
      if (!_.isEmpty(duplicateData)) {
        const _videos = _.cloneDeep(videos);
        _videos.unshift(duplicateData);
        setVideos(_videos);
        shopify.toast.show("Data duplicated successfully!");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const renderCheckbox = (item, column, index) => {
    const checkedItem = selectedList.find((e) => e === item.id);
    const isChecked = checkedItem ? true : false;
    const handleCheck = (newChecked) => {
      let newSelectedList = _.cloneDeep(selectedList);
      if (newChecked) {
        // check
        newSelectedList.push(item.id);
      } else {
        // uncheck
        newSelectedList = selectedList.filter((v) => v !== item.id);
      }
      setSelectedList(newSelectedList);
    };

    return (
      <td
        className="Polaris-DataTable__Cell first-column"
        style={{
          width: `${column.width}%`,
          textAlign: `${column.textAlign}`,
        }}
        key={`video-${index}`}
      >
        <Checkbox checked={isChecked} onChange={handleCheck} />
      </td>
    );
  };

  const renderVideo = (item, column, index) => {
    const {
      fileName = "",
      source = "import",
      views = 0,
      link = "",
      alt = "",
    } = item || {};
    return (
      <td
        className="Polaris-DataTable__Cell column-video"
        style={{
          width: `${column.width}%`,
          textAlign: `${column.textAlign}`,
        }}
        key={`video-${index}`}
      >
        <div>
          <div>
            <div className="video-view">
              <video id="video-view" playsInline>
                <source src={link} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
            <p>{`Views: ${views}`}</p>
          </div>
          <div className="name">
            <p
              style={{
                whiteSpace: "normal", // Enable wrapping for text in the inner div
                wordBreak: "break-word",
                maxWidth: "230px",
              }}
            >
              {fileName}
            </p>
            <p>
              <img
                width={16}
                alt={alt}
                src={VIDEO_SOURCE_TYPES.find((l) => l.name === source)?.url}
              />
            </p>
          </div>
        </div>
      </td>
    );
  };

  const renderRevenue = (item, column, index) => {
    return (
      <td
        className="Polaris-DataTable__Cell column-revenue"
        style={{
          width: `${column.width}%`,
          textAlign: `${column.textAlign}`,
        }}
        key={`revenue-${index}`}
      >
        <div>
          <p>${item.revenue}</p>
        </div>
      </td>
    );
  };

  const renderStatus = (item, column, index) => {
    return (
      <td
        className="Polaris-DataTable__Cell column-status"
        style={{
          width: `${column.width}%`,
          textAlign: `${column.textAlign}`,
        }}
        key={`status-${index}`}
      >
        <div>
          {item?.fileStatus === PROCESSING ? (
            <Badge tone="success">Processing</Badge>
          ) : (
            <Toggle
              defaultChecked={item?.fileStatus === PUBLISHED ? true : false}
              checkedLabel=""
              uncheckedLabel=""
              onChange={(checked) => {
                handleUpdateItem({
                  fileStatus: checked ? UNPUBLISHED : PUBLISHED,
                });
              }}
            />
          )}
        </div>
      </td>
    );
  };
  const renderDateAdded = (item, column, index) => (
    <td
      className="Polaris-DataTable__Cell column-date-added"
      style={{
        width: `${column.width}%`,
        textAlign: `${column.textAlign}`,
      }}
      key={`date-added-${index}`}
    >
      <div>
        <p>
          {item?.createdAt
            ? moment(new Date(item?.createdAt)).format("MMM DD")
            : "-"}
        </p>
      </div>
    </td>
  );
  const renderProducts = (item, column, index) => {
    const products = _.get(item, "products", []);
    return (
      <td
        className="Polaris-DataTable__Cell column-products"
        style={{
          width: `${column.width}%`,
          textAlign: `${column.textAlign}`,
        }}
        key={`products-${index}`}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column", // Arrange content vertically
            justifyContent:
              products?.length > 0 ? "space-between" : "flex-start", // Adjust alignment dynamically
            height: "100%", // Full height of the cell,
            maxWidth: "92px",
          }}
        >
          <div>
            <div className="images">
              {products?.map((product) => (
                <div
                  key={product?.id}
                  className={`product-image ${product?.image ? "" : "no-img"}`}
                >
                  <Thumbnail
                    source={
                      product?.image
                        ? product.image
                        : "https://cdn.iron-pop.com/common/no-img.jpg"
                    }
                    size="small"
                  />
                </div>
              ))}
            </div>
            {products?.length > 0 ? (
              <div style={{ marginTop: "30px" }}>
                <Link onClick={() => setOpenProductModal(true)} removeUnderline>
                  Edit products
                </Link>
              </div>
            ) : (
              <Link onClick={() => setOpenProductModal(true)} removeUnderline>
                + Tag products
              </Link>
            )}
          </div>
        </div>
      </td>
    );
  };
  const renderActions = (item, column, index) => {
    const activator = (
      <span className="group_btn">
        <Button
          icon={MenuHorizontalIcon}
          onClick={() => setPopoverAction(true)}
        />
      </span>
    );
    const menuActions = [
      {
        content: (
          <div style={{ display: "flex", gap: "4px", width: "90px" }}>
            <Icon source={ViewIcon} />
            <p style={{ paddingRight: "4px" }}>View</p>
          </div>
        ),
        id: "view",
        onAction: () => {
          setOpenVideo(true);
          setPopoverAction(false);
        },
      },
      {
        content: (
          <div style={{ display: "flex", gap: "4px", width: "90px" }}>
            <Icon source={EditIcon} />
            <p style={{ paddingRight: "4px" }}>Rename</p>
          </div>
        ),
        id: "rename",
        onAction: () => {
          setOpenEditModal(true);
          setPopoverAction(false);
        },
      },
      {
        content: (
          <div style={{ display: "flex", gap: "4px", width: "90px" }}>
            <Icon source={DuplicateIcon} />
            <p style={{ paddingRight: "4px" }}>Duplicate</p>
          </div>
        ),
        id: "duplicate",
        onAction: () => {
          handleDuplicate();
          setPopoverAction(false);
        },
      },
      {
        content: (
          <div style={{ display: "flex", gap: "4px", width: "90px" }}>
            <Icon source={SaveIcon} />
            <p style={{ paddingRight: "4px" }}>Download</p>
          </div>
        ),
        id: "download",
        onAction: () => {
          handleDownload(item?.fileName, item?.link);
          setPopoverAction(false);
        },
      },
      {
        content: (
          <div style={{ display: "flex", gap: "4px", width: "90px" }}>
            <Icon source={DeleteIcon} />
            <p style={{ paddingRight: "4px" }}>Delete</p>
          </div>
        ),
        id: "delete",
        onAction: () => {
          setOpenDeleteModal(true);
          setPopoverAction(false);
        },
      },
    ];

    return (
      <td
        className="Polaris-DataTable__Cell column-actions"
        style={{
          width: `${column.width}%`,
          textAlign: `${column.textAlign}`,
        }}
        key={`actions-${index}`}
      >
        <div className="actions">
          <Tooltip content={"Copy shareable link"} preferredPosition="above">
            <Button icon={LinkIcon} onClick={() => copyClipBoard(item?.link)} />
          </Tooltip>
          <Popover
            active={popoverAction}
            activator={activator}
            onClose={() => setPopoverAction(false)}
          >
            <Popover.Pane>
              <div className="PopoverActionList-video">
                <ActionList items={menuActions} />
              </div>
            </Popover.Pane>
          </Popover>
        </div>
      </td>
    );
  };
  const renderContent = (item, column, index) => {
    switch (column.value) {
      case "check":
        return renderCheckbox(item, column, index);
      case "video":
        return renderVideo(item, column, index);
      case "revenue":
        return renderRevenue(item, column, index);
      case "status":
        return renderStatus(item, column, index);
      case "createdAt":
        return renderDateAdded(item, column, index);
      case "products":
        return renderProducts(item, column, index);
      case "actions":
        return renderActions(item, column, index);
      default:
        return "";
    }
  };

  return (
    <tr key={`row-${keyVideo}-${item.id}`}>
      {VIDEO_LIBRARY_COLUMN.map((valueCol, index) =>
        renderContent(item, valueCol, index)
      )}
      {openProductModal && (
        <SelectProductModal
          selectedProducts={selectedProducts}
          onChange={(values) => {
            const newProducts = values.map((v) => {
              delete v.isSelected;
              return v;
            });
            handleUpdateItem({ products: newProducts });
          }}
          shop={shop}
          active={openProductModal}
          closeModal={() => setOpenProductModal(false)}
        />
      )}
      <VideoModal
        url={item?.link}
        active={openVideo}
        onClose={() => setOpenVideo(false)}
      />
      <ConfirmDeleteModal
        active={openDeleteModal}
        deleting={deleting}
        onClose={() => setOpenDeleteModal(false)}
        title={"Confirm delete"}
        content={`Are you sure to delete this ${item?.fileName} video`}
        primaryAction={() => handleDelete([item?.id])}
      />
      {openEditModal && (
        <EditModal
          active={openEditModal}
          onClose={() => setOpenEditModal(false)}
          oldName={item?.fileName}
          handleSave={(newName) => {
            handleUpdateItem({ fileName: newName });
            setOpenEditModal(false);
          }}
        />
      )}
    </tr>
  );
};

export default VideoItem;
