import { Button } from "@shopify/polaris";
import React from "react";
import { buildURLPopupBlock } from "../../common/helper";
import { SHOPIFY_EMBED_ID } from "../../common/constants/common";

const appEmbeddedInline = ({ shop }) => {
  const enableAppEmbed = () => {
    const handleURL = buildURLPopupBlock(SHOPIFY_EMBED_ID);
    window.open(`https://${shop.id}/${handleURL}`, "_blank");
  };

  return (
    <div className="IronPop-EmbeddedInlineBanner">
      <p>
        IronPop hasn’t been integrated into your live theme yet, so it won’t be
        active on your store.
      </p>
      <div>
        <Button onClick={enableAppEmbed} variant="monochromePlain">
          Enable
        </Button>
      </div>
    </div>
  );
};

export default appEmbeddedInline;
