import _ from "lodash";
import { Modal, TextField } from "@shopify/polaris";
import React, { useState } from "react";

const EditModal = ({ active, onClose, oldName = "", handleSave }) => {
  const [name, setName] = useState(oldName);
  return (
    <Modal
      open={active}
      onClose={onClose}
      title={"Edit video name"}
      primaryAction={{
        content: "Save",
        disabled: _.isEmpty(name) || !name,
        onAction: () => handleSave(name),
      }}
      secondaryActions={[
        {
          content: "Cancel",
          onAction: onClose,
        },
      ]}
    >
      <Modal.Section>
        <TextField
          label="Enter new video name"
          value={name}
          onChange={(val) => setName(val)}
        />
      </Modal.Section>
    </Modal>
  );
};

export default EditModal;
