import { Modal } from "@shopify/polaris";

const ConfirmDeleteModal = ({
  active,
  onClose,
  title,
  deleting,
  content,
  primaryAction,
}) => {
  return (
    <Modal
      open={active}
      onClose={onClose}
      title={title}
      primaryAction={{
        destructive: true,
        loading: deleting,
        content: "Delete",
        onAction: primaryAction,
      }}
      secondaryActions={[
        {
          content: "Cancel",
          onAction: onClose,
        },
      ]}
    >
      <Modal.Section>{content}</Modal.Section>
    </Modal>
  );
};

export default ConfirmDeleteModal;
