import React, { useEffect } from "react";
import "./style.css";
import { Button } from "@shopify/polaris";
import { XIcon } from "@shopify/polaris-icons";
const VideoModal = ({ url, active, onClose }) => {
  useEffect(() => {
    const videoElement = document.getElementById("iron-pop-video-modal-view");

    if (active && videoElement) {
      videoElement.play(); // Play the video when modal is open
    } else if (!active && videoElement) {
      videoElement.pause(); // Pause the video when modal is closed
    }

    // Lock scrolling when modal is active
    if (active) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = ""; // Restore scrolling
    }

    // Cleanup on unmount or modal close
    return () => {
      if (videoElement) videoElement.pause();
      document.body.style.overflow = ""; // Ensure scrolling is restored
    };
  }, [active]); // Re-run the effect whenever 'active' changes

  return active ? (
    <div className="IronPop-VideoModal">
      <Button onClick={onClose} icon={XIcon} variant="plain" />
      {/* Modal from Shopify Polaris */}

      <div>
        {/* Modal Content */}
        <div className="modal-content">
          {/* Close Button */}

          {/* Video */}
          <video
            id="modal-video"
            controls
            style={{
              width: "100%",
              height: "auto",
            }}
          >
            <source src={url} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default VideoModal;
