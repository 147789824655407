import { IRON_POP_IMAGE_URL } from "./common";

const IMAGE_URL = `${IRON_POP_IMAGE_URL}/pop-types`;

export const POP_TYPES_PROPERTIES = [
  {
    image: `${IMAGE_URL}/sales-pop-img.png`,
    title: "Sales notifications",
    key: "salesNoti",
    content:
      "Boost credibility and sales by displaying recent purchases. Show customers what others are buying to create a sense of a busy store, build trust, and encourage hesitant shoppers. Customizable to match your store's style.",
    primaryAction: "Configure",
    primaryActionUrl: `/pop-types/sales-noti`,
    primaryActionStatus: "active",
    secondaryAction: "",
    toggle: true,
    isNew: false,
    isBeta: false,
  },
  {
    image: `${IMAGE_URL}/order-count-img.png`,
    title: "Order count",
    key: "orderCount",
    content:
      "Display the number of completed purchases in the last 24 hours. Motivate new visitors to buy by showcasing your store's recent activity. Create urgency and boost conversions by demonstrating real-time popularity.",
    primaryAction: "Configure",
    primaryActionUrl: `/pop-types/order-count`,
    primaryActionStatus: "active",
    secondaryAction: "",
    toggle: true,
    isNew: false,
    isBeta: false,
  },
  {
    image: `${IMAGE_URL}/reel-pop-img.png`,
    title: "Reel Pops",
    key: "reelPops",
    content:
      "Capture visitors' attention by showcasing autoplay reels as pop-ups. Use hovering videos on the product detail page (PDP) to engage users and encourage them to make a purchase.",
    primaryAction: "Upload Video",
    primaryActionUrl: "/video-library",
    primaryActionStatus: "active",
    secondaryAction: "",
    toggle: true,
    isNew: false,
    isBeta: true,
  },
  {
    image: null,
    title: "Add-to-cart Pops",
    key: "addToCart",
    content:
      "Display real-time notifications when shoppers add items to their carts. Sync directly with your Shopify store's Add to Cart events to show live shopping activity. Boost social proof and create a sense of demand, encouraging more visitors to make purchases.",
    primaryAction: "Coming soon",
    primaryActionUrl: "",
    primaryActionStatus: "disable",
    secondaryAction: "",
    toggle: false,
    isNew: false,
    isBeta: false,
  },
  {
    image: null,
    title: "Product page live view",
    key: "liveView",
    content:
      "Showcase your product popularity in real-time. Watch your website come alive with dynamic engagement metrics. Encourage purchases by displaying live popularity data to visitors.",
    primaryAction: "Coming soon",
    primaryActionUrl: "",
    primaryActionStatus: "disable",
    secondaryAction: "",
    toggle: false,
    isNew: false,
    isBeta: false,
  },
];
