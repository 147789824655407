import { request, uploadShopifyVideo } from "./base-api";

const videoLibraryApi = {
  /**
   * get list video
   * @param {*} shopuuid
   * @param {shop: string, skip: number, limit: number} queries
   * @returns
   */
  getListVideo: (shopuuid, queries) => {
    return request("admin-api.video-library.get-list-video", {
      params: { shopuuid },
      queries,
    });
  },

  /**
   * get video status
   * @param {*} shopuuid
   * @param {{shop: string, fileName: string}} queries
   * @returns
   */
  getVideoStatus: (shopuuid, queries) => {
    return request("admin-api.video-library.get-video-status", {
      params: { shopuuid },
      queries,
    });
  },
  /**
   * Update video
   * @param {*} shopuuid
   * @param {{filename: string, status: string, productIds: Array<number>}} body
   * @returns
   */
  updateVideo: (shopuuid, id, body) => {
    return request("admin-api.video-library.update-video", {
      params: { shopuuid, id },
      body,
    });
  },
  /**
   * Update many videos
   * @param {*} shopuuid
   * @param {Array<string>} ids
   * @param {{ fileStatus: 'UNPUBLISHED' | 'PUBLISHED'}} props
   * @returns
   */
  updateManyVideo: (shopuuid, ids, props) => {
    return request("admin-api.video-library.update-many-video", {
      params: { shopuuid },
      body: {
        ids,
        props,
      },
    });
  },
  /**
   * Upload video
   * @param {*} shopuuid
   * @param {{files: Array<File>} body
   * @returns
   */
  uploadVideo: (shopuuid, body) => {
    return uploadShopifyVideo({
      params: { shopuuid },
      body,
    });
  },
  /**
   * Upload video
   * @param {*} shopuuid
   * @param {{file: File}} body
   * @returns
   */
  duplicateVideo: (shopuuid, id) => {
    return request("admin-api.video-library.duplicate-video", {
      params: { shopuuid, id },
    });
  },
  /**
   * Delete video by id
   * @param {*} shopuuid
   * @param {string} id
   * @returns
   */
  deleteVideo: (shopuuid, ids, shopifyVideoIds) => {
    return request("admin-api.video-library.delete-video", {
      params: { shopuuid },
      body: {
        ids,
        shopifyVideoIds,
      },
    });
  },
};

export default videoLibraryApi;
